<template>
  <div id="token" class="token-sale p-tb c-l">
    <div class="container mt-6">
      <div class="text-center"><h2 class="section-heading">Token <span>Allocation</span></h2></div>
      <div class="sub-txt text-center">
        <p>Tokenomics is an important consideration for crypto projects, as it can have a significant impact on the success and value of a project. Below you find detailed information on our tokenomics, including our token design, allocation, and distribution.</p>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="token-allocation-box style-3">
            <div class="token-sale-left">
              <div class="sale-list">
                <h4>Total Supply</h4>
                <div class="price-tag">
                  <span>1.000.000.000</span>
                </div>
              </div>
            </div>
            <div class="token-sale-right">
              <div class="sale-list">
                <h4>Seed SALE</h4>
                <div class="price-tag">
                  <span>10m ELRS = 0,385 BNB</span>
                  Bonus 10%
                </div>
              </div>
              <div class="sale-list">
                <h4>PRIVATE SALE</h4>
                <div class="price-tag">
                  <span>10m ELRS = 0,35 BNB</span>
                </div>
              </div>
            </div>
            <el-divider width="84%" />
            <div class="token-sale-left">
              <div class="sale-list">
                <h4>Buy Tax</h4>
                <div class="price-tag">
                  <span>9%</span>
                </div>
              </div>
            </div>
            <div class="token-sale-right">
              <div class="sale-list">
                <h4>Team</h4>
                <div class="price-tag">
                  <span>3%</span>
                </div>
              </div>
              <div class="sale-list">
                <h4>Treasury</h4>
                <div class="price-tag">
                  <span>6%</span>
                </div>
              </div>
            </div>
            <el-divider width="84%" />
            <div class="token-sale-left">
              <div class="sale-list">
                <h4>Sell Tax</h4>
                <div class="price-tag">
                  <span>15%</span>
                </div>
              </div>
            </div>
            <div class="token-sale-right">
              <div class="sale-list">
                <h4>Team</h4>
                <div class="price-tag">
                  <span>5%</span>
                </div>
              </div>
              <div class="sale-list">
                <h4>Treasury</h4>
                <div class="price-tag">
                  <span>10%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 wow zoomIn">
          <h4>Token Allocation</h4>
          <div class="sale-chart-view">
            <div class="doughnut">
              <div class="doughnutChartContainer">
                <canvas id="doughnutChart" height="270" />
              </div>
              <div id="legend" class="chart-legend" />
            </div>
          </div>
        </div>
        <div class="col-md-6 wow zoomIn">
          <h4>Private Allocation</h4>
          <div class="sale-chart-view">
            <div class="doughnut">
              <div class="doughnutChartContainer">
                <canvas id="layout2doughnutChart1" height="270" />
              </div>
              <div id="layout2legend1" class="chart-legend" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <h4>Wallet Addresses</h4>
          <div class="token-allocation-box row">
            <div class="wallet-container col-md-12">
              <h4>Smart Contract</h4>
              <div class="main-wallet wallet">
                <span>{{ ca }}</span>
                <a href="" @click.prevent="onCopy"><i class="fa fa-copy" /></a>
              </div>
            </div>
            <el-divider width="84%" />
            <div class="wallet-container col-md-6">
              <h4>Development</h4>
              <div class="wallet">
                <span>0xB78F0b26A01a7e23c0B817f00369445E4CF19CDF</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Late Investors</h4>
              <div class="wallet">
                <span>0x30819CF2916DA97FaE79333B9666F50E762812da</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Rewards</h4>
              <div class="wallet">
                <span>0x1E35134E8FcAa89D0a0eb3d97F55E25a942d6201</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Marketing</h4>
              <div class="wallet">
                <span>0x097444cf19E01De41A941594c8C749B6530695f0</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Founders and Team</h4>
              <div class="wallet">
                <span>0xE551a9dAaD8Ba974bC47398F9c361BB61Cd0A647</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Reserves</h4>
              <div class="wallet">
                <span>0xbD3Da63AE2c4D8aBb0F3E0615C418AF12A05dB0B</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Seed Sale</h4>
              <div class="wallet">
                <span>0x8a8C32AFbA8Da08dd2e4d266F8071Caed4D3126C</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Private Sale - 1y vesting</h4>
              <div class="wallet">
                <span>0x357dE59C4C138D6fB76B914D7DFb3Bfd4ba1b0f2</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Private Sale - 6m vesting</h4>
              <div class="wallet">
                <span>0x597669Bdda77884C5A4e2f56B3a2714D80aea062</span>
              </div>
            </div>
            <div class="wallet-container col-md-6">
              <h4>Private Sale - 3m vesting</h4>
              <div class="wallet">
                <span>0x45cF83611a186B8E0FE83c105Bfc25791f4603d4</span>
              </div>
            </div>
            <el-divider width="84%" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Token Sale end -->
</template>

<script>
  import Vue from 'vue';
  import { nanoid } from 'nanoid';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';

  export default Vue.extend({
    name: 'elros-tokenomics',
    data: () => ({
      ca: '0xe916efb77f2ec7fcb277e34a51bab5ff306db41c',
    }),
    mounted() {
      window.initTokenomics();
    },
    methods: {
      onCopy() {
        copyToClipboard(this.ca);
        this.$toasts.toast({
          id: nanoid(),
          message: 'The link has been copied to clipboard',
          title: 'Success',
          intent: 'success',
        });
      },
    },
  });
</script>

<style>
.wallet-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0 10px;
}

.wallet-container h4 {
  font-size: 18px;
  text-transform: uppercase;
}

.wallet-container .wallet {
  font-size: 18px;
  line-height: 1.8;
  text-transform: uppercase;
}

.wallet-container .wallet span {
  color: #eec05e;
  display: block;
  font-weight: bold;
}

@media only screen and (max-width: 1199px) {
  .wallet-container .wallet {
    font-size: 12px;
  }
}
</style>
