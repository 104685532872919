var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"token-sale p-tb c-l",attrs:{"id":"token"}},[_c('div',{staticClass:"container mt-6"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"token-allocation-box style-3"},[_vm._m(2),_vm._m(3),_c('el-divider',{attrs:{"width":"84%"}}),_vm._m(4),_vm._m(5),_c('el-divider',{attrs:{"width":"84%"}}),_vm._m(6),_vm._m(7)],1)])]),_vm._m(8),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('h4',[_vm._v("Wallet Addresses")]),_c('div',{staticClass:"token-allocation-box row"},[_c('div',{staticClass:"wallet-container col-md-12"},[_c('h4',[_vm._v("Smart Contract")]),_c('div',{staticClass:"main-wallet wallet"},[_c('span',[_vm._v(_vm._s(_vm.ca))]),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.onCopy.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-copy"})])])]),_c('el-divider',{attrs:{"width":"84%"}}),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_c('el-divider',{attrs:{"width":"84%"}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"section-heading"},[_vm._v("Token "),_c('span',[_vm._v("Allocation")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sub-txt text-center"},[_c('p',[_vm._v("Tokenomics is an important consideration for crypto projects, as it can have a significant impact on the success and value of a project. Below you find detailed information on our tokenomics, including our token design, allocation, and distribution.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"token-sale-left"},[_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Total Supply")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("1.000.000.000")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"token-sale-right"},[_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Seed SALE")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("10m ELRS = 0,385 BNB")]),_vm._v(" Bonus 10% ")])]),_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("PRIVATE SALE")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("10m ELRS = 0,35 BNB")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"token-sale-left"},[_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Buy Tax")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("9%")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"token-sale-right"},[_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Team")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("3%")])])]),_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Treasury")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("6%")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"token-sale-left"},[_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Sell Tax")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("15%")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"token-sale-right"},[_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Team")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("5%")])])]),_c('div',{staticClass:"sale-list"},[_c('h4',[_vm._v("Treasury")]),_c('div',{staticClass:"price-tag"},[_c('span',[_vm._v("10%")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 wow zoomIn"},[_c('h4',[_vm._v("Token Allocation")]),_c('div',{staticClass:"sale-chart-view"},[_c('div',{staticClass:"doughnut"},[_c('div',{staticClass:"doughnutChartContainer"},[_c('canvas',{attrs:{"id":"doughnutChart","height":"270"}})]),_c('div',{staticClass:"chart-legend",attrs:{"id":"legend"}})])])]),_c('div',{staticClass:"col-md-6 wow zoomIn"},[_c('h4',[_vm._v("Private Allocation")]),_c('div',{staticClass:"sale-chart-view"},[_c('div',{staticClass:"doughnut"},[_c('div',{staticClass:"doughnutChartContainer"},[_c('canvas',{attrs:{"id":"layout2doughnutChart1","height":"270"}})]),_c('div',{staticClass:"chart-legend",attrs:{"id":"layout2legend1"}})])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Development")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0xB78F0b26A01a7e23c0B817f00369445E4CF19CDF")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Late Investors")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0x30819CF2916DA97FaE79333B9666F50E762812da")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Rewards")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0x1E35134E8FcAa89D0a0eb3d97F55E25a942d6201")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Marketing")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0x097444cf19E01De41A941594c8C749B6530695f0")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Founders and Team")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0xE551a9dAaD8Ba974bC47398F9c361BB61Cd0A647")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Reserves")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0xbD3Da63AE2c4D8aBb0F3E0615C418AF12A05dB0B")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Seed Sale")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0x8a8C32AFbA8Da08dd2e4d266F8071Caed4D3126C")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Private Sale - 1y vesting")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0x357dE59C4C138D6fB76B914D7DFb3Bfd4ba1b0f2")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Private Sale - 6m vesting")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0x597669Bdda77884C5A4e2f56B3a2714D80aea062")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wallet-container col-md-6"},[_c('h4',[_vm._v("Private Sale - 3m vesting")]),_c('div',{staticClass:"wallet"},[_c('span',[_vm._v("0x45cF83611a186B8E0FE83c105Bfc25791f4603d4")])])])
}]

export { render, staticRenderFns }